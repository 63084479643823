import "./css/login.css";
import { useAtom } from "jotai";
import mixpanel from "mixpanel-browser";
import { ReactComponent as CheckIcon } from "../assets/icons/check-icon.svg";
import { useCreateDraftFromBookings } from "../hook";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ADVERTISER_LOGIN } from "../components/GraphQL/Mutation";
import { useEffect, useRef, useState } from "react";
import { isBookingState } from "../atom/advertiserModal";
import { AUTH_TOKEN } from "../constant";
import { toast } from "react-toastify";
import Logo from "../components/Logo";
import { CloseIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Formik, useFormik } from "formik";
import ResetPassword from "../components/resetPassword";
import SignupWrapper from "../components/SignupWrapper";
import logo from "../transmitAssets/transmitLogo.svg";

const Login = ({
  navTextColor = "#004643",
  navFavColor = "#FC9732",
  closeModal,
  openAdvertiserSignupModal,
  openRadioStationSignupModal,
}) => {
  const [isBooking] = useAtom(isBookingState);
  const { loading: createDraftLoading, createDraft } =
    useCreateDraftFromBookings();

  const navigate = useNavigate();

  const [login, { loading }] = useMutation(ADVERTISER_LOGIN);

  const [reset, setReset] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const checkMark = useRef();
  const Modal = useRef();

  // useEffect(() => {
  //   checkMark.current.style.display = "none";
  // }, [checkMark]);

  // useEffect(() => {
  //   if (reset) {
  //     document.body.style.height = "100vh";
  //     document.body.style.overflow = "hidden";
  //     Modal.current.style.display = "flex";
  //   } else {
  //     document.body.style.height = "100%";
  //     document.body.style.overflow = "auto";
  //     Modal.current.style.display = "none";
  //   }
  // }, [reset]);

  function handleRemmeberMe() {
    if (checkMark.current.style.display === "none") {
      checkMark.current.style.display = "block";
    } else {
      checkMark.current.style.display = "none";
    }
  }

  function handleResetPassword(e) {
    const child = Modal.current.querySelector("#reset-password");
    if (!child.contains(e.target)) {
      setReset(false);
    }
  }

  function handleLogin(values) {
    // track user event
    mixpanel.track("login");

    login({
      variables: {
        input: {
          email: values.email,
          password: values.password,
        },
      },
      onCompleted: handleLoginComplete,
      onError(err) {
        toast.error(err?.networkError?.result?.errors[0].message);
      },
    });
  }

  function handleLoginComplete(data) {
    // save jwt token
    localStorage.setItem(AUTH_TOKEN, data.auth.login.token);
    // Set the ID in localStorage
    localStorage.setItem("ID", data.auth.login.id);
    mixpanel.identify(data?.auth?.login?.id);
    console.log("Data----", data);

    const isVerified = data.auth.login.isVerified;
    const userGroup = data.auth.login.group;

    if (!isVerified) {
      return setTimeout(() => {
        navigate(`/signup/verify-account/${userGroup}`);

        closeModal(false);
        toast.success("Login successful");
      }, 1000);
    }

    if (userGroup.toLowerCase() === "advertiser") {
      // Advertiser
      if (isBooking) {
        // if user is booking a campaign before the login prompt return back to booking
        return createDraft(JSON.parse(localStorage.getItem("booking")));
      } else {
        setTimeout(navigate("/dashboard"), 1000);
      }
    } else if (userGroup.toLowerCase() === "admin") {
      setTimeout(navigate("/dashboard/admin"), 1000);
    } else {
      // Broadcaster
      setTimeout(navigate("/dashboard/vendor"), 1000);
    }

    closeModal(false);
    toast.success("Login successful");
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    /* and other goodies */
  } = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: (values) => {
      mixpanel.track("login");

      login({
        variables: {
          input: {
            email: values.email,
            password: values.password,
          },
        },
        onCompleted: handleLoginComplete,
        onError(err) {
          toast.error(err?.networkError?.result?.errors[0].message);
        },
      });
    },
    // validationSchema: BroadcasterSignupSchema,
  });

  return (
    <>
      <SignupWrapper>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="transmitLogoForSignupWrapper">
            <img src={logo} alt="logo" />
          </div>

          <div className="transmitSignUpWrapper" style={{ padding: "30px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                justifyItems: "start",
                alignItems: "start",
              }}
            >
              <div
                className="login-modal-header"
                onClick={() => closeModal(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="transmitSignUpTitleWrapper">
              <p className="transmitSignUpTitle loginHeader">Login</p>
              <p className="transmitSignUpSubTitle loginSubHeader">L<span className="lower-case">og In to your dashboard</span></p>
            </div>

            {/* </div> */}
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "50px",
                marginTop: "50px",
              }}
            >
              <div className="transmitFormContainer">
                <div className="formInputWrapper">
                  <label className="formInputLabel">Email</label>
                  <input
                    type="email"
                    className="formInput"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="formInputWrapper">
                  <label className="formInputLabel">Password</label>
                  <input
                    type="password"
                    className="formInput"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="rememberMeAndForgotPasswordWrapper">
                  <div className="rememberMeWrapper">
                    <input type="checkbox" />
                    <p>Remember me</p>
                  </div>
                  <div className="forgotPasswordWrapper">Forgot Password</div>
                </div>
                <button type="submit" className="transmitBtn btn-continue">
                  {loading ? (
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </form>

            <div
              style={{ marginTop: "20px", textAlign: "left" }}
              className="dont-have"
            >
              Don’t have an account? Sign Up as an{" "}
              {/* <Link
            to="/signup"
            id="go-to-signup"
            onClick={() => closeModal(false)}
          > */}
              <span
                className="changeTextColor"
                onClick={() => {
                  openAdvertiserSignupModal(true);
                  closeModal(false);
                }}
              >
                Advertiser{" "}
              </span>
              {/* </Link>{" "} */}
              or as a{" "}
              {/* <Link
                to="/signup/vendor"
                id="go-to-signup"
              > */}
              <span
                className="changeTextColor"
                onClick={() => {
                  openRadioStationSignupModal(true);
                  closeModal(false);
                }}
              >
                Radio Station
              </span>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </SignupWrapper>
    </>
  );
};

export default Login;
