import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/recommended-station.css";
import Station from "../Station.js";
import { GET_BROADCASTERS } from "../../../components/GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import fallBackImage from "../../../asset/img/fallback-station-img.jpeg";
import { SpinnerContainer, Spinner } from "./components";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import mixpanel from "mixpanel-browser";
import { CancelIcon } from "../Svg";
// import radioStation from "../../../assets/radioStation.png";

const RecommendedStation = ({
  countrystateobj,
  searchState,
  location,
  checkBox,
  setCheckBox,
  countrystatename,
  filterByPriceRange,
}) => {
  const [selectedStation, setSelectedStation] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);
  const [check, setCheck] = useState(false);

  const navigate = useNavigate();

  const [visibleDataCount, setVisibleDataCount] = useState(12);

  const showLess = () => {
    setVisibleDataCount(12);
  };

  localStorage.removeItem(checkBox);

  let selectStation = (data) => {
    setSelectedStation([...selectedStation, data]);
    setClearSelect(true);
  };
  // console.log("selectStation", selectStation);
  let deSelectStation = (data) => {
    setSelectedStation(selectedStation?.filter((a) => a.id !== data.id));
  };
  const [broadcasterData, setBroadcasterData] = useState([]);
  const { data, loading } = useQuery(GET_BROADCASTERS, {
    variables: {
      page: 1,
      itemsPerPage: 1000,
    },
  });
  useEffect(() => {
    if (searchState === true) {
      return setBroadcasterData(countrystateobj);
    }
    if (location.length) {
      const broadcasterArray =
        data?.guest?.getBroadcasters?.broadcasters?.filter(({ state }) =>
          location.includes(state)
        );
      return setBroadcasterData(broadcasterArray);
    }
    if (!searchState) {
      return setBroadcasterData(data?.guest?.getBroadcasters?.broadcasters);
    }
  }, [searchState, data, countrystateobj, location]);

  useEffect(() => {
    localStorage.removeItem("selectedStations");
    setCheckBox([]);
  }, []);
  // console.log(broadcasterData);
  //clear selected stations
  const maxCount = broadcasterData?.filter(
    (broadcaster) => broadcaster?.isApproved
  ).length;

  const loadMore = () => {
    const nextCount = visibleDataCount + 12;

    const updatedCount = Math.min(nextCount, maxCount);

    setVisibleDataCount(updatedCount);
  };

  const clearStations = () => {
    if (clearSelect === false) {
      localStorage.removeItem("selectedStations");
      setClearSelect(true);
      setCheckBox([]);
    } else {
      setClearSelect(false);
    }
    setTimeout(() => {
      setClearSelect(false);
    }, 1000);
  };

  const selectedRadioStations = localStorage.getItem("selectedStations") ?? "";
  const getSelectedStations = selectedRadioStations
    ? selectedRadioStations.split(",")
    : [];

  const filteredData = broadcasterData?.filter((item) => {
    return getSelectedStations.includes(item?.id);
  });

  // console.log("filteredData", filteredData);

  const handleBooking = () => {
    // track user event
    mixpanel.track("select_radio_sations");

    if (!checkBox.length) {
      return toast.error("you've not select a radio station");
    }
    {
      // navigate(`/book-campaign/campaign-details?stations=${checkBox}`);
      navigate(`/book-campaign/campaign-details`);
    }
  };
  // console.log("checkBox--", checkBox);

  const handleCheck = (id) => {
    // console.log("id--", id);
    let mutatedSelectedStation = selectedRadioStations.length
      ? [...selectedRadioStations.split(",")]
      : [];

    // Always remove the ID from checkBox and mutatedSelectedStation
    const updatedCheckBox = checkBox.filter((station) => station !== id);
    setCheckBox(updatedCheckBox);
    mutatedSelectedStation = mutatedSelectedStation.filter(
      (station) => station !== id
    );

    if (mutatedSelectedStation.length) {
      localStorage.setItem(
        "selectedStations",
        mutatedSelectedStation.join(",")
      );
    } else {
      localStorage.removeItem("selectedStations"); 
    }
  };

  return (
    <div id="recommended-station">
      <div>
        <div>
          <h2>Radio Stations</h2>
        </div>

        {/* INPUT FILEDS */}
        <div></div>

        {/* END OF INPUT FIELDS */}
      </div>
      <div id="recommended-bar"></div>

      <div>
        <div id="filter-station-ctrl">
        </div>
        {!loading ? (
          <div id="recommended-station-list">
            {broadcasterData?.filter(
              (broadcaster) => broadcaster.isApproved === true
            ).length > 0 ? (
              <div>
                {broadcasterData
                  ?.filter((broadcaster) => broadcaster.isApproved === true)
                  .slice(0, visibleDataCount)
                  .map((station, index) => {
                    return (
                      <Station
                        key={station.id}
                        id={station.id}
                        img={station.banner ?? fallBackImage}
                        name={station.name}
                        currency={station.stationCurrency}
                        startingPrice={station?.startingPrice}
                        discountedStatingPrice={station.discountedStatingPrice}
                        discountPercentage={station.discountPercentage}
                        clearSelect={clearSelect}
                        select={selectStation}
                        deSelect={deSelectStation}
                        setCheckBox={setCheckBox}
                        checkBox={checkBox}
                        check={check}
                        setCheck={setCheck}
                      />
                    );
                  })}
              </div>
            ) : (
              <div className="not-found">
                <p>No Verified Data available</p>
              </div>
            )}
          </div>
        ) : (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </div>
      <div>
        <div id="recommendation_load">
          {broadcasterData?.filter((broadcaster) => broadcaster?.isApproved)
            .length > visibleDataCount && (
            <button type="button" onClick={loadMore}>
              LOAD MORE
            </button>
          )}
          {broadcasterData?.filter((broadcaster) => broadcaster?.isApproved)
            .length === visibleDataCount && (
            <button onClick={showLess}>SHOW LESS</button>
          )}
        </div>
        {/* <button className="book_now_btn" onClick={handleBooking}>
          Book Now
        </button> */}
      </div>

      {/* FIXED FOOTER */}

      {filteredData?.length > 0 && (
        <div id="recommedation_footer_fixed">
          <div id="recommendation_clear">
            <button onClick={clearStations}>Clear all</button>
          </div>

          <div id="recommendation_body">
            <div id="recommendation_footer_content">
              {/* SELECTED RADIO STATIONS */}
              {filteredData?.length !== 0 &&
                filteredData?.map((station, index) => {
                  return (
                    <div id="footer_pic" key={station?.id}>
                      <div id="img_wrapper">
                        <div id="radiostation_img">
                          <img
                            src={station?.banner ?? fallBackImage}
                            alt="selected_station"
                          />
                        </div>
                        <div
                          id="cancel_icon"
                          onClick={() => handleCheck(station?.id)}
                        >
                          <CancelIcon />
                        </div>
                      </div>
                      <div>
                        <p id="img_text">{station?.name?.split(" ")[0]}</p>
                      </div>
                    </div>
                  );
                })}
              {/* END OF SELECTED RADIO STATIONS */}
            </div>
            <div id="book_now_wrapper">
              <button id="book_now_btn" onClick={handleBooking}>
                Book Now
              </button>
            </div>
          </div>
        </div>
      )}

      {/* END OF FIXED FOOTER */}
    </div>
  );
};

export default RecommendedStation;
