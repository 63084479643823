import React, { useEffect, useRef, useState } from "react";
import "./css/station-details.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./css/station-details.css";
import moment from "moment";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  useQuery,
} from "@chakra-ui/react";
import styled from "styled-components";
import Svg from "./Svg";
import { BROADCASTER_SLOTS_GQL } from "../../components/GraphQL/Queries";

const StationDetails = ({
  title,
  tagline,
  isOpen,
  setMutatedBroadcasterData,
  mutatedbroadcasterData,
  broadcasterData,
  setLanguage,
  broadcasterId,
  totalPrice,
}) => {
  const {
    isOpen: isOpenChakra,
    onOpen: openDatePicker,
    onClose,
  } = useDisclosure();
  const [slotId] = useState(null);
  const [slotQuantity] = useState(0);
  const [advertTypeSelected, setAdvertTypeSelected] = useState(null);
  const [checkedSelectedSlot, setCheckedSelectedSlot] = useState([]);
  const [openSlotModal, setOpenSlotModal] = useState(false);
  // const [priceTag, setPriceTag] = useState(null);

  // const [slotNumber, setSlotNumber] = useState({});
  // console.log("pricetag", data);

  const [isChecked, setIsChecked] = useState(false);
  const [
    duplicateSelectedSlotsAcrossDatesIfDateChange,
    setDuplicateSelectedSlotsAcrossDatesIfDateChange,
  ] = useState(false);

  const [bookingInfo, setBookingInfo] = useState({
    advertType: "",
    duration: "",
    language: "",
    slotId: slotId,
    slotQuantity: slotQuantity,
  });

  const handleChange = (e) => {
    setLanguage(e.target.value);
    const newbroadcasterData = mutatedbroadcasterData.map((broadcasterData) => {
      broadcasterData = { ...broadcasterData };
      if (broadcasterData["guest"].name === title) {
        broadcasterData["guest"]["language"] = e.target.value;
      }
      return broadcasterData;
    });
    setMutatedBroadcasterData(newbroadcasterData);
    setBookingInfo({
      ...bookingInfo,
      [e.target.name]: e.target.value,
    });
  };

  const startDatePicker = useRef();
  const startDateView = useRef();
  const endDatePicker = useRef();
  const endDateView = useRef();

  const currentDateValue = new Date();
  currentDateValue.setDate(currentDateValue.getDate() + 1);
  const currDateFormatted = currentDateValue.toISOString().split("T")[0];
  // console.log(advertTypeSelected);

  const handleAdvertType = (e) => {
    const { value } = e.target;
    setBookingInfo({ ...bookingInfo, advertType: value });
    const newbroadcasterData = mutatedbroadcasterData.map((broadcasterData) => {
      const currDate = moment(new Date()).add(1, "day").format("YYYY-MM-DD");
      broadcasterData = { ...broadcasterData };
      if (broadcasterData.guest.name === title) {
        broadcasterData["guest"]["advertType"] = value;
        broadcasterData["guest"]["startDate"] = new Date(currDateFormatted);
        broadcasterData["guest"]["endDate"] = new Date(currDateFormatted);
        broadcasterData["guest"]["dateRange"] = {};
        broadcasterData["guest"]["dateRange"][`${currDate}`] = {
          slotData: [
            ...broadcasterData?.guest?.getBroadcasterSlots.map((slot) => {
              slot = { ...slot };
              slot["date"] = currDate;
              slot["language"] = "";
              return slot;
            }),
          ],
          totalSlot: 0,
          totalPrice: 0,
        };

        broadcasterData["guest"]["totalDays"] = 1;
        broadcasterData["guest"]["totalSpots"] = 0;
        broadcasterData["guest"]["totalPrice"] = 0;
      }
      return broadcasterData;
    });
    setMutatedBroadcasterData(newbroadcasterData);
  };
  const handleAdvertDur = (e) => {
    const { value } = e.target;
    setAdvertTypeSelected(value);
    const currDate = moment(new Date()).add(1, "day").format("YYYY-MM-DD");
    const newbroadcasterData = mutatedbroadcasterData.map((broadcasterData) => {
      broadcasterData = { ...broadcasterData };
      if (broadcasterData.guest.name === title) {
        broadcasterData["guest"]["duration"] = value;
        broadcasterData["guest"]["startDate"] = new Date(currDateFormatted);
        broadcasterData["guest"]["endDate"] = new Date(currDateFormatted);
        broadcasterData["guest"]["dateRange"] = {};
        broadcasterData["guest"]["dateRange"][`${currDate}`] = {
          slotData: [
            ...broadcasterData?.guest?.getBroadcasterSlots.map((slot) => {
              slot = { ...slot };
              slot["date"] = currDate;
              return slot;
            }),
          ],
          totalSlot: 0,
          totalPrice: 0,
        };

        broadcasterData["guest"]["totalDays"] = 1;
        broadcasterData["guest"]["totalSpots"] = 0;
        broadcasterData["guest"]["totalPrice"] = 0;
      }
      return broadcasterData;
    });
    setMutatedBroadcasterData([...newbroadcasterData]);
    setBookingInfo({ ...bookingInfo, duration: value });
  };

  function getDates(startDate, stopDate) {
    let dateArray = [];
    let currentDate = moment(startDate);
    stopDate = moment(stopDate);
    while (currentDate < stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }

    const formattedStopDate = moment(stopDate).format("YYYY-MM-DD");

    // const containsStopDate = dateArray.some(
    //   (date) => date === formattedStopDate
    // );
    // if (containsStopDate) {
    //   return dateArray;
    // }

    dateArray = [...dateArray, formattedStopDate];
    return dateArray;
  }

  const [slotDataList, setSlotDataList] = useState([]);

  const getAdvertType = () => {
    if (broadcasterData.advertType === "Jingle") {
      if (broadcasterData.duration === "15Sec") {
        return "Jingle15Sec";
      }
      if (broadcasterData.duration === "30Sec") {
        return "Jingle30Sec";
      }
      if (broadcasterData.duration === "45Sec") {
        return "Jingle45Sec";
      }
      if (broadcasterData.duration === "60Sec") {
        return "Jingle60Sec";
      }
    }
    if (broadcasterData.advertType === "paidAdvertisements") {
      if (broadcasterData.duration === "50Words") {
        return "Ann50Words";
      }
      if (broadcasterData.duration === "75Words") {
        return "Ann75Words";
      }
      if (broadcasterData.duration === "100Words") {
        return "Ann100Words";
      }
    }
    return "";
  };

  const onChangeDateHandler = (e, date) => {
    const currentDate = new Date(e.target.value);
    const startDate = new Date(broadcasterData?.startDate);
    const endDate = new Date(broadcasterData?.endDate);
  
    let currentBroadcasterData = { ...broadcasterData?.dateRange };
    let dateRangeList = [];
  
    if (date === "start") {
      dateRangeList = getDates(currentDate, endDate);
    } else if (date === "end") {
      dateRangeList = getDates(startDate, currentDate);
    }
  
    const updatedBroadcasterData = Object.fromEntries(
      Object.entries(currentBroadcasterData)
        .filter(([date]) => dateRangeList.includes(date))
        .map(([date, data]) => [date, data])
    );
  
    currentBroadcasterData = updatedBroadcasterData;
  
    setDuplicateSelectedSlotsAcrossDatesIfDateChange(
      !duplicateSelectedSlotsAcrossDatesIfDateChange
    );
  
    dateRangeList.forEach((date) => {
      if (!currentBroadcasterData[date]) {
        currentBroadcasterData[date] = {
          slotData: broadcasterData?.getBroadcasterSlots.map((slot) => ({
            ...slot,
            date,
            advertType: getAdvertType(),
            slotNumber: 0,
            language: broadcasterData?.language,
          })),
          totalSlot: 0,
          totalPrice: 0,
          date,
        };
      }
    });
  
    const newbroadcasterData = mutatedbroadcasterData.map((data) => {
      if (data.guest.name === title) {
        data.guest.totalDays = dateRangeList.length;
        if (date === "start") {
          data.guest.startDate = currentDate;
        } else if (date === "end") {
          data.guest.endDate = currentDate;
        }
        data.guest.dateRange = { ...currentBroadcasterData };
      }
      return { ...data };
    });
  
    setMutatedBroadcasterData(newbroadcasterData);
  };
  
  const [currentDate, setCurrentDate] = useState("");

  const currentDateList =
    broadcasterData?.dateRange[`${moment(currentDate).format("YYYY-MM-DD")}`]
      ?.slotData;

  const activeStation = mutatedbroadcasterData.find(
    (radioStation) => radioStation.guest.id === broadcasterId
  );

  const slotInputChangeHandler = (e, { id }) => {
    // Convert input value to a number
    const castedNumberVal = Number(e.target.value);
    const numberCheck = isNaN(castedNumberVal);

    // Mutate slotDataList based on input changes
    const mutatedDataList = slotDataList?.slotData?.map((slot) => {
      // Clone the slot object to avoid mutating original data
      slot = { ...slot };

      // Check if the current slot matches the input id
      if (slot?.id === id) {
        slot["slotNumber"] = !numberCheck ? castedNumberVal : slot?.slotNumber;
        const currNum = !numberCheck ? castedNumberVal : slot?.slotNumber;

        // setSlotNumber((prevInputData) => ({
        //   ...prevInputData,
        //   [id]: castedNumberVal,
        // }));

        if (broadcasterData.advertType === "Jingle") {
          if (broadcasterData.duration === "15Sec") {
            slot["cost"] = slot["Jingle15SecPrice"] * currNum;
          }
          if (broadcasterData.duration === "30Sec") {
            slot["cost"] = slot["Jingle30SecPrice"] * currNum;
          }
          if (broadcasterData.duration === "45Sec") {
            slot["cost"] = slot["Jingle45SecPrice"] * currNum;
          }
          if (broadcasterData.duration === "60Sec") {
            slot["cost"] = slot["Jingle60SecPrice"] * currNum;
          }
        }
        if (broadcasterData.advertType === "paidAdvertisements") {
          if (broadcasterData.duration === "50Words") {
            slot["cost"] = slot["Ann50WordsPrice"] * Number(e.target.value);
          }
          if (broadcasterData.duration === "75Words") {
            slot["cost"] = slot["Ann75WordsPrice"] * Number(e.target.value);
          }
          if (broadcasterData.duration === "100Words") {
            slot["cost"] = slot["Ann100WordsPrice"] * Number(e.target.value);
          }
        }
      }

      return slot;
    });

    const current = mutatedDataList.find((slot) => slot.id === id);
    let currTotalSlot = !numberCheck ? castedNumberVal : current?.slotNumber;
    const currTotalSlotList =
      currentDateList && currentDateList.filter((slot) => slot?.id !== id);

    for (let index = 0; index < currTotalSlotList.length; index++) {
      const element = currTotalSlotList[index];
      currTotalSlot += element?.slotNumber ?? 0;
    }

    let currTotalPrice = 0;

    for (let index = 0; index < mutatedDataList.length; index++) {
      const element = mutatedDataList[index];
      currTotalPrice += element?.cost ?? 0;
    }

    let newData = { ...broadcasterData };
    newData["dateRange"][`${moment(currentDate).format("YYYY-MM-DD")}`][
      "slotData"
    ] = [...mutatedDataList];
    newData["dateRange"][`${moment(currentDate).format("YYYY-MM-DD")}`][
      "totalSlot"
    ] = currTotalSlot;
    newData["dateRange"][`${moment(currentDate).format("YYYY-MM-DD")}`][
      "totalPrice"
    ] = currTotalPrice;

    const newBroadcaster = mutatedbroadcasterData.map((broadcaster) => {
      broadcaster = { ...broadcaster };
      if (broadcaster.name === title) {
        broadcaster.dateRange = { ...newData?.dateRange };
      }
      return broadcaster;
    });

    setMutatedBroadcasterData(newBroadcaster);

    setSlotDataList((prevState) => ({
      ...prevState,
      slotData: mutatedDataList,
    }));

    // Handle additional logic when isChecked is true
    if (activeStation.guest.isChecked) {
      const selectedRadioStationInfo = mutatedbroadcasterData.filter(
        (radioStation) => radioStation.guest.id !== broadcasterId
      );
      const getRadioStationWithSelectedSlot = mutatedbroadcasterData.filter(
        (radioStation) => radioStation.guest.id === broadcasterId
      );
      const newData = [...getRadioStationWithSelectedSlot];

      // Calculate costsForCurrentSlot for the current input id
      const costsForCurrentSlot = newData.map(({ guest }) => {
        const { slotData } = guest.dateRange[currentDate];
        const slotWithInputId = slotData.find((s) => s.id === id);
        return slotWithInputId.cost;
      });

      newData.forEach(({ guest }) => {
        const dateKeys = Object.keys(guest.dateRange);

        dateKeys.forEach((dateKey) => {
          // Update slotData, totalSlot, and totalPrice for each dateKey
          const slotData = guest.dateRange[dateKey].slotData;
          const updatedSlotData = slotData.map((slot) => {
            if (slot.id === id) {
              const updatedSlot = {
                ...slot,
                slotNumber: castedNumberVal,
                cost: costsForCurrentSlot[0],
              };
              return updatedSlot;
            }
            return slot;
          });
          guest.dateRange[dateKey].slotData = updatedSlotData;

          const totalSlot = updatedSlotData
            .map((slotInfo) => slotInfo.slotNumber)
            .filter((slotNum) => slotNum > 0)
            .reduce((total, slot) => total + slot, 0);

          const totalPrice = updatedSlotData
            .filter((slotInfo) => slotInfo.hasOwnProperty("cost"))
            .map(({ cost }) => cost)
            .reduce((total, cost) => total + cost, 0);

          guest.dateRange[dateKey].totalSlot = totalSlot;
          guest.dateRange[dateKey].totalPrice = totalPrice;
        });

        // Calculate totalSpots and totalPrice for each guest
        const getTotalSpots = dateKeys.map(
          (date) => guest.dateRange[date].totalSlot
        );
        const getTotalSpotsPrice = dateKeys.map(
          (date) => guest.dateRange[date].totalPrice
        );

        guest.totalSpots = getTotalSpots.reduce(
          (initialNoSpot, spotValue) => initialNoSpot + spotValue,
          0
        );
        guest.totalPrice = getTotalSpotsPrice.reduce(
          (initialAmount, spotAmount) => initialAmount + spotAmount,
          0
        );
      });

      // Update mutatedBroadcasterData with new data
      setMutatedBroadcasterData([newData[0], ...selectedRadioStationInfo]);
    }
  };

  const saveHandler = () => {
    ModalClose();
    const allSlots = Object.values(broadcasterData.dateRange).reduce(
      (acc, curr) => acc + curr.totalSlot,
      0
    );
    const allCosts = Object.values(broadcasterData.dateRange).reduce(
      (acc, curr) => acc + curr.totalPrice,
      0
    );

    const allSlotsData = Object.values(broadcasterData.dateRange)
      .map((slot) => {
        return [...slot.slotData];
      })
      .flat()
      .filter((slotData) => !!slotData.slotNumber)
      .map((slot) => {
        slot = { ...slot };
        slot["advertType"] = getAdvertType();
        slot["language"] = broadcasterData?.language;

        return slot;
      });

    const newbroadcasterData = mutatedbroadcasterData.map((broadcasterData) => {
      broadcasterData = { ...broadcasterData };
      if (broadcasterData["guest"].name === title) {
        broadcasterData["guest"].totalSpots = allSlots;
        broadcasterData["guest"].totalPrice = allCosts;
        broadcasterData["guest"].allSlots = [...allSlotsData];
      }
      return broadcasterData;
    });

    setMutatedBroadcasterData(newbroadcasterData);

    onClose();
  };

  const currentNumberofDays =
    broadcasterData && Object.values(broadcasterData?.dateRange).length;

  const duplicateSelectedSlotsAcrossDates = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    const updateRadioStationData = () => {
      // if (currentNumberofDays > 1) {

      const selectedRadioStationInfo = mutatedbroadcasterData.filter(
        (radioStation) => radioStation.guest.id !== broadcasterId
      );

      const getRadioStationWithSelectedSlot = mutatedbroadcasterData.filter(
        (radioStation) => radioStation.guest.id === broadcasterId
      );


      const updatedRadioStationWithSelectedIsChecked =
        getRadioStationWithSelectedSlot.map(({ guest }) => ({
          guest: {
            ...guest,
            isChecked: isChecked,
          },
        }));

      // const isCheckedValue = updatedRadioStationWithSelectedIsChecked[0].guest.isChecked
      const isCheckedValue = updatedRadioStationWithSelectedIsChecked.map(
        ({ guest }) => guest.isChecked
      );

      if (isCheckedValue[0]) {
        const newData = [...updatedRadioStationWithSelectedIsChecked];
        const slotDataAcrossDates = newData
          .map(({ guest }) => Object.values(guest.dateRange))
          .flatMap((item) => item)
          .map(({ slotData }) => slotData)
          .flatMap((slotDataObj) => slotDataObj)
          .filter((a) => a.slotNumber > 0);

        newData.forEach(({ guest }) => {
          const dateKeys = Object.keys(guest.dateRange);

          dateKeys.forEach((dateKey) => {
            const slotData = guest.dateRange[dateKey].slotData;

            // Duplicate the first slotData object for other objects with the same ID
            for (let i = 0; i < slotData.length; i++) {
              const matchingSlot = slotDataAcrossDates.find(
                (existSlot) => slotData[i].id === existSlot.id
              );
              if (matchingSlot) {
                slotData[i] = { ...matchingSlot, date: dateKey };
              }
            }
            const totalSlot = slotData
              .map((slotInfo) => slotInfo.slotNumber)
              .filter((slotNum) => slotNum > 0)
              .reduce((total, slot) => total + slot, 0);

            const totalPrice = slotData
              .map((slotInfo) => slotInfo)
              .filter((slotNum) => slotNum.hasOwnProperty("cost"))
              .map(({ cost }) => cost)
              .reduce((total, slot) => total + slot, 0);

            guest.dateRange[dateKey].totalSlot = totalSlot;
            guest.dateRange[dateKey].totalPrice = totalPrice;
          });
          const getTotalSpots = dateKeys.map(
            (date) => guest.dateRange[date].totalSlot
          );
          const getTotalSpotsPrice = dateKeys.map(
            (date) => guest.dateRange[date].totalPrice
          );
          guest.totalSpots = getTotalSpots.reduce(
            (initialNoSpot, spotValue) => initialNoSpot + spotValue
          );
          guest.totalPrice = getTotalSpotsPrice.reduce(
            (initialAmount, spotAmount) => initialAmount + spotAmount
          );
        });
        return setMutatedBroadcasterData([
          newData[0],
          ...selectedRadioStationInfo,
        ]);
      } else {
        const newData = [...updatedRadioStationWithSelectedIsChecked];

        newData.forEach(({ guest }) => {
          const dateKeys = Object.keys(guest.dateRange);

          dateKeys.forEach((dateKey) => {
            const slotData = guest.dateRange[dateKey].slotData;

            // Remove duplicate slots based on ID
            const uniqueSlots = slotData.filter(
              (slot, index, self) =>
                self.findIndex((s) => s.id === slot.id) === index
            );

            // Remove duplicate slotNumbers across different dates
            const uniqueSlotNumbers = Array.from(
              new Set(uniqueSlots.map((slot) => slot.slotNumber))
            );

            // Update slotNumbers for other dates
            dateKeys.forEach((otherDateKey) => {
              if (otherDateKey !== dateKey) {
                const otherDateSlots = guest.dateRange[otherDateKey].slotData;
                const updatedOtherDateSlots = otherDateSlots.map((slot) => {
                  if (uniqueSlotNumbers.includes(slot.slotNumber)) {
                    return { ...slot, slotNumber: 0 };
                  }
                  return slot;
                });
                guest.dateRange[otherDateKey].slotData = updatedOtherDateSlots;
              }
            });

            guest.dateRange[dateKey].slotData = uniqueSlots;

            const totalSlot = uniqueSlots
              .map((slotInfo) => slotInfo.slotNumber)
              .filter((slotNum) => slotNum > 0)
              .reduce((total, slot) => total + slot, 0);

            const totalPrice = uniqueSlots
              .filter((slotInfo) => slotInfo.hasOwnProperty("cost"))
              .map((getSoltDataArrayWithSlot) => getSoltDataArrayWithSlot)
              .filter((slot) => slot.slotNumber > 0)
              .map(({ cost }) => cost)
              .reduce((total, slot) => total + slot, 0);

            guest.dateRange[dateKey].totalSlot = totalSlot;
            guest.dateRange[dateKey].totalPrice = totalPrice;
          });

          const getTotalSpots = dateKeys.map(
            (date) => guest.dateRange[date].totalSlot
          );
          const getTotalSpotsPrice = dateKeys.map(
            (date) => guest.dateRange[date].totalPrice
          );

          guest.totalSpots = getTotalSpots.reduce(
            (initialNoSpot, spotValue) => initialNoSpot + spotValue,
            0
          );
          guest.totalPrice = getTotalSpotsPrice.reduce(
            (initialAmount, spotAmount) => initialAmount + spotAmount,
            0
          );
        });

        setMutatedBroadcasterData([newData[0], ...selectedRadioStationInfo]);
      }

      // }
    };

    updateRadioStationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, duplicateSelectedSlotsAcrossDatesIfDateChange]);

  useEffect(() => {
    setIsChecked(false);
  }, [broadcasterData.advertType, broadcasterData.duration]);

  // Get the current date
  const getcurrentDate = new Date();
  getcurrentDate.setDate(getcurrentDate.getDate() + 1); // Set it to a day after the current day

  const handleCheckboxChange = (e) => {
    const id = e.target.value;
    const isSelected = e.target.checked;

    // Find the index of the slotData in slotDataList
    const index = slotDataList?.slotData.findIndex((item) => item.id === id);

    if (index !== -1) {
      // Update the isSelected property directly
      slotDataList.slotData[index].isSelected = isSelected;

      // Update checkedSelectedSlot with the updated object
      setCheckedSelectedSlot({
        ...checkedSelectedSlot,
        slotData: slotDataList.slotData.filter((item) => item.isSelected),
      });

      // Update slotDataList with the modified item
      setSlotDataList((prevSlotDataList) => {
        const updatedSlotDataList = [...prevSlotDataList.slotData];
        updatedSlotDataList[index].isSelected = isSelected;
        return { ...prevSlotDataList, slotData: updatedSlotDataList };
      });
    }
  };

  const ModalOpen = () => {
    onClose();
    setOpenSlotModal(true);
  };

  //function to close second modal
  const ModalClose = () => {
    setOpenSlotModal(false);
  };

  return (
    <div id="station-detail-cnt">
      <div id="station-title">
        <div>
          <h2>{title}</h2>
          <h3>{tagline}</h3>
        </div>
        <button onClick={isOpen}>View Time Schedule</button>
      </div>
      <div id="select-advert-details">
        <div>
          <label htmlFor="advert-type">Advert Type</label>
          <div>
            <select
              value={broadcasterData?.advertType}
              name="advertType"
              onChange={(e) => handleAdvertType(e)}
            >
              <option value="">select advert type</option>
              {broadcasterData?.advertTypeData?.map((type, index) => (
                <option value={type.value} key={index}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          <p>Check Time Schedule for shows in your selected time slots.</p>
        </div>
        <div>
          <label htmlFor="duration">Duration</label>
          <div>
            <select
              name="duration"
              onChange={(e) => handleAdvertDur(e)}
              defaultValue="select advert duration"
              value={broadcasterData?.duration}
            >
              <option value="">select advert duration</option>
              {broadcasterData?.advertTypeData &&
                broadcasterData?.advertTypeData
                  .find((dur) => dur.value === broadcasterData?.advertType)
                  ?.advertTypeDuration?.map((durationType) => (
                    <option value={durationType.val}>{durationType.dur}</option>
                  ))}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="language">
            Select your preferred language for this campaign
          </label>
          <div>
            <select
              name="language"
              onChange={handleChange}
              value={broadcasterData?.language ?? ""}
            >
              <option value="">select language</option>
              <option value="English">English</option>
              <option value="Pidgin">Pidgin</option>
              <option value="Yoruba">Yoruba</option>
              <option value="Igbo">Igbo</option>
              <option value="Hausa">Hausa</option>
            </select>
          </div>
        </div>
      </div>
      <div id="select-advert-time">
        <h2>
          You have selected {currentNumberofDays} Day
          {currentNumberofDays > 1 ? "s" : ""}
        </h2>
        <div id="select-date">
          <div>
            <label htmlFor="start-date">Start Date</label>
            <div>
              <input
                id="start-date"
                type="date"
                // min={minDate}
                min={getcurrentDate.toISOString().slice(0, 10)}
                ref={startDatePicker}
                disabled={
                  !broadcasterData?.language || !broadcasterData?.duration
                }
                value={moment(broadcasterData?.startDate).format("YYYY-MM-DD")}
                // value={broadcasterData?.startDate}
                onChange={(e) => onChangeDateHandler(e, "start")}
              />
              <input
                ref={startDateView}
                className="formatted-date"
                type="text"
                placeholder={`${moment(broadcasterData?.startDate).format(
                  "MMM Do YY"
                )}`}
                readOnly
              />
            </div>
          </div>
          <div>
            <label htmlFor="end-date">End Date</label>
            <div>
              <input
                id="end-date"
                type="date"
                min={getcurrentDate.toISOString().slice(0, 10)}
                ref={endDatePicker}
                disabled={
                  !broadcasterData?.language || !broadcasterData?.duration
                }
                // value={broadcasterData?.endDate}
                value={moment(broadcasterData?.endDate).format("YYYY-MM-DD")}
                onChange={(e) => onChangeDateHandler(e, "end")}
              />
              <input
                ref={endDateView}
                className="formatted-date"
                type="text"
                placeholder={`${moment(broadcasterData?.endDate).format(
                  "MMM Do YY"
                )}`}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      <div id="set-time-slot">
        <h2>Click on dates to input spots.</h2>
        <>
          <Modal isOpen={openSlotModal} onClose={ModalClose} size={"md"}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <ModalHeaderText>
                  What time do you want to play your ad?
                </ModalHeaderText>
              </ModalHeader>
              <ModalCloseButton color="#004643" />
              <ModalBody>
                <SlotContainer>
                  {slotDataList &&
                    slotDataList?.slotData?.map(
                      ({ startTime, endTime, id, isSelected }) => {
                        const checkboxId = `checkbox_${id}`;

                        return (
                          <div className="adchecker" key={id}>
                            <input
                              type="checkbox"
                              className="slotbox"
                              id={checkboxId}
                              value={id}
                              key={id}
                              checked={isSelected || false}
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <label htmlFor={checkboxId}></label>
                            <InputLeft>
                              <SlotDataTimeText>{`${moment(startTime).format(
                                "LT"
                              )}- ${moment(endTime).format(
                                "LT"
                              )}`}</SlotDataTimeText>
                            </InputLeft>
                          </div>
                        );
                      }
                    )}
                </SlotContainer>
              </ModalBody>

              <ModalFooter>
                <div className="checkbox_footer">
                  <Button
                    mr={3}
                    onClick={() => {
                      openDatePicker();
                    }}
                  >
                    Next
                  </Button>
                </div>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
        <>
          <Modal isOpen={isOpenChakra} onClose={onClose} size={"md"}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <ModalHeaderText>
                  {/* Input Slot Numbers for {`${moment(currentDate).format("LL")}`} */}

                  <div className="slectedslot_header">
                    <div className="slectedslot_btn" onClick={onClose}>
                      <Svg />
                    </div>
                    <div className="selectedslot_txt">
                      <div>Enter no of times to play ad</div>
                    </div>
                  </div>
                </ModalHeaderText>
              </ModalHeader>
              {/* <ModalCloseButton /> */}
              <ModalBody>

                <SlotContainerInput>
                  {slotDataList &&
                    slotDataList?.slotData?.map(
                      ({
                        startTime,
                        endTime,
                        id,
                        isSelected,
                        slotNumber = 0,
                        Jingle60SecPrice,
                        Jingle45SecPrice,
                        Jingle30SecPrice,
                        Jingle15SecPrice,
                        Ann50WordsPrice,
                        Ann100WordsPrice,
                        Ann75WordsPrice,
                      }) => {
                        const inputId = `input_${id}`;
                        let price;

                        if (isSelected) {
                          switch (advertTypeSelected) {
                            case "60Sec":
                              price = Jingle60SecPrice;
                              break;
                            case "45Sec":
                              price = Jingle45SecPrice;
                              break;
                            case "30Sec":
                              price = Jingle30SecPrice;
                              break;
                            case "15Sec":
                              price = Jingle15SecPrice;
                              break;
                            case "50Words":
                              price = Ann50WordsPrice;
                              break;
                            case "100Words":
                              price = Ann100WordsPrice;
                              break;
                            case "75Words":
                              price = Ann75WordsPrice;
                              break;
                            default:
                              break;
                          }
                        }
                        return (
                          <>
                            {isSelected && (
                              <div className="selectedSlot_wrappper">
                                <div className="selectedTime_wrappper">
                                  <div>
                                    <InputSlotTextSelected>
                                      <SlotDataText>{`${moment(
                                        startTime
                                      ).format("LT")}- ${moment(endTime).format(
                                        "LT"
                                      )} `}</SlotDataText>
                                      <div className="add_wrapper">
                                        <p>
                                          {price ? `N${price}/ad` : 0}
                                        </p>
                                      </div>
                                    </InputSlotTextSelected>
                                  </div>
                                </div>
                                <div>
                                  <Input
                                    placeholder="Basic usage"
                                    w="100%"
                                    border={"2px solid #aba8a8"}
                                    borderColor="#EDEDED"
                                    id={inputId}
                                    borderRadius={"none"}
                                    h="43px"
                                    value={slotNumber}
                                    onChange={(e) =>
                                      slotInputChangeHandler(e, { id })
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        );
                      }
                    )}

                </SlotContainerInput>

              </ModalBody>

              <ModalFooter>
                <div className="footer_wrap">
                  <div className="custom-checkbox-container">
                    <div
                      className="checkmark-box"
                      onClick={() => duplicateSelectedSlotsAcrossDates()}
                    >
                      {/* {a && setIsCheckedForActiveRadiostation()} */}
                      <span
                        className={
                          activeStation.guest.isChecked && "checkmark-tick"
                        }
                      ></span>
                    </div>
                    <p>
                      D
                      <span style={{ textTransform: "lowercase" }}>
                        uplicate order across chosen dates
                      </span>
                    </p>
                  </div>
                  <div className="checkbox_footer">
                    <Button mr={3} onClick={saveHandler}>
                      Done
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
        <DatePicker
          selected={broadcasterData?.startDate}
          startDate={broadcasterData?.startDate}
          endDate={broadcasterData?.endDate}
          maxDate={broadcasterData?.endDate}
          minDate={broadcasterData?.startDate}
          onSelect={(date) => {
            let currDate = moment(date).format("YYYY-MM-DD");
            const currSlotDataList = broadcasterData?.dateRange[`${currDate}`];
            setCurrentDate(currDate);
            setSlotDataList(currSlotDataList);
            ModalOpen();
          }}
          selectsRange
          inline
          selectsDisabledDaysInRange
          excludeDates={
            (!broadcasterData?.duration || !broadcasterData?.language) && [
              broadcasterData?.startDate,
              broadcasterData?.endDate,
            ]
          }
        />
      </div>
    </div>
  );
};
export default StationDetails;
const SlotContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  row-gap: 30px;
  height: 270px;
  overflow-y: scroll;
  margin-top: 30px;

`;

const SlotContainerInput = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  row-gap: 65px;
  overflow-y: scroll;
  margin-top: 30px;
  height: 270px;

`;

const InputContainer = styled.div`
  width: 213px;
  height: 44px;
  border: 0.8px solid #aba8a8;
  border-radius: 2px;
  display: flex;
`;

const InputLeft = styled.div`
  width: 144px;
  height: 44px;
  background: #ededed;
  border-radius: 2px 0px 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InputSlotText = styled.div`
  width: 144px;
  height: 60px;
  background: #ededed;
  border-radius: 2px 0px 0px 2px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding: 5px;
`;
const InputSlotTextSelected = styled.div`
  width: 144px;
  height: 60px;
  border-radius: 2px 0px 0px 2px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding: 5px;
`;

const SlotDataTimeText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #0e0e2c;
`;
const SlotDataText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* identical to box height */
  color: #0e0e2c;
`;

const ModalHeaderText = styled.h2`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #004643;
`;
