import React, { useState } from "react";
import { useParams } from "react-router-dom";
import DownloadDocument from "../components/BookingDetails/DownloadDocument/DownloadDocument";
import { StationButtons } from "../components/BookingDetails/StationButtons/StationButtons";
import BookingInfo from "../components/BookingDetails/BookingInfoDetails/BookingInfo";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { Box } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { GET_ORDER_BY_ID } from "../components/GraphQL/Queries";
import { TableSpinner } from "../components/Spinner";

const AdminOrderDetails = () => {
  const [activeButton, setActiveButton] = useState();
  const [campaignDetails, setCampaignDetails] = useState(null);
  const { id } = useParams();
  const { data, loading } = useQuery(GET_ORDER_BY_ID, {
    variables: {
      orderId: parseInt(id),
    },
    onCompleted: () => {
      setActiveButton(data?.admin.getOrderById.radioStationDetails[0]?.id);
      setCampaignDetails(data?.admin.getOrderById.radioStationDetails[0]);
    },
  });
  const singleCampaignHandler = (index) => {
    setActiveButton(index);
    const singleCampaign = data?.admin.getOrderById.radioStationDetails.filter(
      (campaign) => {
        return campaign.id === index;
      }
    );
    return setCampaignDetails(singleCampaign[0]);
  };

  return (
    <AdminDashboardLayout>
      {loading ? (
        <TableSpinner />
      ) : (
        <Box px={30} pt={10}>
          <DownloadDocument campaignDetails={data?.admin.getOrderById} />
          <StationButtons
            radioStationDetails={data?.admin.getOrderById.radioStationDetails}
            activeButton={activeButton}
            onButtonClick={singleCampaignHandler}
          />
          <BookingInfo
            campaignDetails={campaignDetails}
            campaignId={data?.admin.getOrderById.id}
          />
        </Box>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminOrderDetails;
