import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../transmitAssets/logo-and-text.svg";
import hamburger from "../../transmitAssets/hamburger.svg";
import mobileLogo from "../../transmitAssets/transmitLogo.svg";
import SignupModal from "../Modal/signup/SignupModal";
import Login from "../../pages/login";
import styled from "styled-components";
import { ExistingLoginUser, isTokenValid } from "../../utils/functions";
import { useLoginModalState } from "../../hook";
import "./style.css";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
// import AdvertiserSignup from "../Modal/AdvertiserSignup";
import AdvertiserSignup from "../../pages/signup/advertiserSignup";
import SignupVendor from "../../pages/signup/VendorSignup";
const TransmitNavbar = () => {
  const navigate = useNavigate();
  const { loginModal, setLoginModal } = useLoginModalState();

  const [openModal, setOpenModal] = useState(false);
  const [loginDropdown, setLoginDropdown] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setLoginDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [loginDropdown]);

  const modalHandler = () => {
    onClose();
    setOpenModal(true);
  };

  const [activeMenu, setActiveMenu] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onClose: closeModal } = useDisclosure();
  const [open, setOpen] = useState(false);
  const [openRadioStationSignupModal, setOpenRadioStationSignupModal] =
    useState(false);

  const OpenAdvertiser = () => {
    setOpenModal(false);
    setOpen(true);
  };
  const openRadioStationSignupModalHandler = () => {
    setOpenModal(false);
    setOpenRadioStationSignupModal(true);
  };

  const CloseAdvertiser = () => {
    setOpen(false);
  };

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "selectedMenu" : "";
  };

  const tokenFromLocalStorage = localStorage.getItem("token");

  const tokenStillValid = isTokenValid(tokenFromLocalStorage);

  const navigateUserType = () => {
    const advertType = ExistingLoginUser(tokenFromLocalStorage);
    if (advertType === "Broadcaster") {
      return navigate("/dashboard/vendor");
    }
    if (advertType === "Advertiser") {
      return navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  console.log(tokenStillValid, "llll");

  return (
    <>
      {openModal && (
        <SignupModal
          closeModal={setOpenModal}
          OpenAdvertiser={OpenAdvertiser}
          openRadioStationSignupModalHandler={
            openRadioStationSignupModalHandler
          }
        />
      )}
      {loginModal && (
        <Login
          closeModal={setLoginModal}
          openAdvertiserSignupModal={setOpen}
          openRadioStationSignupModal={setOpenRadioStationSignupModal}
        />
      )}
      {open && <AdvertiserSignup closeModal={setOpen} />}
      {openRadioStationSignupModal && (
        <SignupVendor closeModal={setOpenRadioStationSignupModal} />
      )}
      <div className="transmitNavbarWrapper">
        <div className="transmitDesktop">
          <div className="transmitMenuListWrapper">
            <ul className="transmitMenuLists">
              <li
                className={`transmitMenuList ${isActive("/")}`}
                // onClick={() => handleSetActiveMenu(1)}
              >
                <Link to="/">Advertiser</Link>
              </li>
              <li className={`transmitMenuList ${isActive("/radio-station")}`}>
                <Link to="/radio-station">Radio station</Link>
              </li>
              <li className={`transmitMenuList ${isActive("/blog")}`}>
                <Link to="https://more.radioadspread.com/blog/">Blog</Link>
              </li>
              <li className={`transmitMenuList ${isActive("/faq")}`}>
                <Link to="">FAQ</Link>
              </li>
            </ul>
          </div>

          <div className="desktopLogo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          {!tokenStillValid ? (
            <div className="authBtns">
              <div
                //  to="/auth/signup"
                onClick={modalHandler}
              >
                <span className="transmitSignupBtn transmitRegisterBtn">
                  Sign up
                </span>
              </div>
              <div
                onClick={() => setLoginModal(!loginModal)}
                // to="/auth/login"
              >
                <span className="transmitLoginBtn transmitRegisterBtn">
                  Login
                </span>
              </div>
            </div>
          ) : (
            <div
              //  to="/auth/signup"
              onClick={() => navigateUserType()}
            >
              <span className="transmitSignupBtn transmitRegisterBtn">
                Dashboard
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="transmitMobile">
        <div className="transmitMobileContent">
          <img src={mobileLogo} alt="logo" className="mobileTransmitLogo" />
          <img
            src={hamburger}
            alt="hamburger"
            className="drawerIcon"
            onClick={onOpen}
          />
        </div>
        <Drawer
          isOpen={isOpen}
          placement="right"
          size="md"
          // initialFocusRef={firstField}
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent backgroundColor="black" p={30}>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row-reverse"
            >
              <DrawerCloseButton color="white" size="xl" fontSize={20} />
              <DrawerHeader borderBottomWidth="1px">
                <div className="transmitMobileHeader">
                  <img src={logo} alt="logo" className="drawerTransmitLogo" />
                  {/* <img src={mobileLogo} alt="logo" className="mobileTransmitLogo" /> */}
                </div>
              </DrawerHeader>
            </Box>

            <DrawerBody pt={40}>
              <Stack spacing="50px">
                <ul className="mobileMenuList">
                  <li
                    className={`mobileTransmitMenuList  ${
                      location.pathname === "/" && "activeTransmitMenuList"
                    }`}

                    // onClick={() => setActiveMenu(1)}
                  >
                    <Link to="/">Advertiser</Link>
                  </li>
                  <li
                    className={`mobileTransmitMenuList ${
                      location.pathname === "/radio-station" &&
                      "activeTransmitMenuList"
                    }`}
                    // onClick={() => setActiveMenu(2)}
                  >
                    <Link to="/radio-station">Radio station</Link>
                  </li>
                  <li
                    className={`mobileTransmitMenuList ${
                      location.pathname === "/blog" && "activeTransmitMenuList"
                    }`}
                    // onClick={() => setActiveMenu(3)}
                  >
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li
                    className={`mobileTransmitMenuList ${
                      location.pathname === "" && "activeTransmitMenuList"
                    }`}
                    // onClick={() => setActiveMenu(4)}
                  >
                    <Link to="/">FAQ</Link>
                  </li>
                </ul>
                {!tokenStillValid ? (
                  <div className="authBtns">
                    <div
                      //  to="/auth/signup"
                      onClick={modalHandler}
                    >
                      <span className="transmitSignupBtn transmitRegisterBtn">
                        Sign up
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        onClose();
                        setLoginModal(!loginModal);
                      }}
                      // to="/auth/login"
                    >
                      <span className="transmitLoginBtn transmitRegisterBtn">
                        Login
                      </span>
                    </div>
                  </div>
                ) : (
                  <div
                    //  to="/auth/signup"
                    onClick={() => navigateUserType()}
                  >
                    <span className="transmitSignupBtn transmitRegisterBtn">
                      Dashboard
                    </span>
                  </div>
                )}
              </Stack>
            </DrawerBody>

            {/* <DrawerFooter borderTopWidth='1px'>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue'>Submit</Button>
          </DrawerFooter> */}
          </DrawerContent>
        </Drawer>
      </div>
    </>
  );
};

export default TransmitNavbar;
