import React, { useState } from "react";
import "./css/page-one.css";
import BudgetFilter from "../components/BudgetFilter.js";
import RecommendedStation from "../components/RecommendedStation/RecommendedStation";
import Newsletter from "../../components/Newsletter.js";
import MainFooterSect from "../../components/MainFooterSect.js";
import { MyContext } from "../../pages/BookCampaign";
import SearchRadioStation from "../components/SearchRadioStation";
import Footer from "../../components/Footer/index.jsx";

const PageOne = () => {
  const { countrystateinfo, search, getSearchStatus, getLocation } = React.useContext(MyContext);

 

  const [filterLocation, setFilterLocation] = useState("");
  const [countrystatename, setCountryStateName] = useState({
    countryName: "",
    stateName: "",
  });
  const [budgetPrice, setBudgetPrice] = useState();
  const getSearch = (data) => {
    setFilterLocation(data);
  };
  const priceFilterRange = (data) => {
    setBudgetPrice(data);
  };
  const [checkBox, setCheckBox] = useState([]);

  return (
    <div id="book-campaign">
      <main>
        <SearchRadioStation
          stationLocation={getLocation}
          status={getSearchStatus}
          setCountryStateName={setCountryStateName}
        />
        <section id="select-station-sect">
          <div>
            {/* <BudgetFilter
              searchState={getSearch}
              priceFilter={priceFilterRange}
            /> */}
            <RecommendedStation
              countrystateobj={countrystateinfo}
              searchState={search}
              location={filterLocation}
              filterByPriceRange={budgetPrice}
              countrystatename={countrystatename}
              checkBox={checkBox}
              setCheckBox={setCheckBox}
            />
          </div>
        </section>
      </main>
      <footer id={checkBox.length !== 0 ? "footer_sx" : undefined}>
        {/* <Newsletter /> */}
        {/* <MainFooterSect /> */}
        <Footer />
      </footer>
    </div>
  );
};

export default PageOne;
