import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ADVERTISER_REGISTER } from "../../components/GraphQL/Mutation";
import { useMutation } from "@apollo/client";
import { AUTH_TOKEN } from "../../constant";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
// import { Country, State } from "country-state-city";
import "../css/signup-advertiser.css";
import { CloseIcon } from "@chakra-ui/icons";
import { useLoginModalState } from "../../hook";
import { AdvertiserSignupSchema } from "../../utils/validations";
import Login from "../login";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AdvertiserSignupWrapper from "../../components/AdvertiserSignupWrapper";

const AdvertiserSignup = ({ closeModal }) => {
  const navigate = useNavigate();

  const { loginModal, setLoginModal } = useLoginModalState();

  const [userType] = useState("Individual");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmAgreement, setConfirmAgreement] = useState(true);

  // const [states, setStates] = useState([]);

  const [signup, { loading }] = useMutation(ADVERTISER_REGISTER);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      referralCode: "",
      accountType: userType,
    },
    validationSchema: AdvertiserSignupSchema,
    onSubmit() {
      // track user event
      mixpanel.track("sign_up");
      signup({
        variables: {
          input: {
            fullname: `${values.firstName} ${values.lastName}`,
            email: values.email,
            phoneNumber: values.phoneNumber,
            password: values.password,
            confirmPassword: values.confirmPassword,
            referralCode: values.referralCode,
            accountType: values.accountType,
          },
        },
        onCompleted: (data) => {
          localStorage.setItem(AUTH_TOKEN, data.auth.advertiserRegister.token);
          setTimeout(navigate("/signup/verify-account/advertiser"), 50000);
          mixpanel.identify(data?.auth?.advertiserRegister?.id);
          toast.success("account created");
        },
        onError(err) {
          toast.error(err.networkError.result.errors[0].message);
        },
      });
    },
  });

  return (
    <>
      {loginModal && <Login closeModal={setLoginModal} />}
      <AdvertiserSignupWrapper>
        <div className="advertiser-form-wrapper">
          <div className="advertiser-first-sec">
            <div className="create-account-first">
              <p className="create-acct-texted">Create Your Account</p>
              <p className="select-acct-type">
                Please select your preferred account type
              </p>
            </div>

            <div
              className="login-modal-header"
              onClick={() => closeModal(false)}
            >
              <CloseIcon />
            </div>
          </div>

          <div className="advertiser-second-sec">
            <div className="advertiser-type-button">
              <button
                type="button"
                onClick={() => setFieldValue("accountType", "Individual")}
                className={
                  values.accountType === "Individual"
                    ? "active-btn"
                    : "not-active"
                }
              >
                Individual
              </button>
              <button
                type="button"
                onClick={() => setFieldValue("accountType", "company")}
                className={
                  values.accountType === "company" ? "active-btn" : "not-active"
                }
              >
                Company
              </button>
            </div>
            <div>
              <form onSubmit={handleSubmit}>
                <div className="form-container">
                  <div className="input-container">
                    {values.accountType === "company" && (
                      <div className="form-input-wrapper-advertiser">
                        {errors.companyName && touched.companyName && (
                          <span className="form-error">
                            {" "}
                            {errors.companyName}
                          </span>
                        )}
                        <div className="full-length">
                          <label
                            htmlFor="radio-station"
                            className="title-advert"
                          >
                            Company name
                          </label>
                          <input
                            id="radio-statio"
                            type="text"
                            placeholder=""
                            name="companyName"
                            value={values.companyName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>
                      </div>
                    )}
                    {values.accountType === "Individual" ? (
                      <div className="advertiser-row">
                        <div className="form-input-wrapper-advertiser">
                          {errors.firstName && touched.firstName && (
                            <span className="form-error">
                              {" "}
                              {errors.firstName}
                            </span>
                          )}
                          <div className="full-length">
                            <label
                              htmlFor="radio-station"
                              className="title-advert"
                            >
                              First Name
                            </label>
                            <input
                              // id="radio-station"
                              type="text"
                              placeholder=""
                              name="firstName"
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-input-wrapper-advertiser">
                          {errors.lastName && touched.lastName && (
                            <span className="form-error">
                              {" "}
                              {errors.lastName}
                            </span>
                          )}
                          <div className="full-length">
                            <label
                              htmlFor="radio-station"
                              className="title-advert"
                            >
                              Last Name
                            </label>
                            <input
                              // id="radio-station"
                              type="text"
                              placeholder=""
                              name="lastName"
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="form-input-wrapper-advertiser">
                      {errors.email && touched.email && (
                        <span className="form-error"> {errors.email}</span>
                      )}
                      <div className="full-length">
                        <label htmlFor="email" className="title-advert">
                          Email Address
                        </label>
                        <input
                          id="email"
                          type="email"
                          placeholder=""
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-input-wrapper-advertiser">
                      {errors.phoneNumber && touched.phoneNumber && (
                        <span className="form-error">
                          {" "}
                          {errors.phoneNumber}
                        </span>
                      )}
                      <div className="full-length">
                        <label htmlFor="phone-no" className="title-advert">
                          Phone Number
                        </label>
                        <input
                          // id="radio-station"
                          type="text"
                          placeholder=""
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>
                    </div>

                    <div className="advertiser-row">
                      <div className="form-input-wrapper-advertiser">
                        {errors.password && touched.password && (
                          <span className="form-error"> {errors.password}</span>
                        )}
                        <div className="full-length">
                          <label
                            htmlFor="login-password"
                            className="title-advert"
                          >
                            Password
                          </label>
                          <input
                            // id="login-password"
                            type="password"
                            placeholder=""
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                          <button
                            type="button"
                            id="show-password"
                            onClick={() => setShowPassword(!showPassword)}
                          ></button>
                        </div>
                      </div>
                      <div className="form-input-wrapper-advertiser">
                        {errors.confirmPassword && touched.confirmPassword && (
                          <span className="form-error">
                            {" "}
                            {errors.confirmPassword}
                          </span>
                        )}
                        <div className="full-length">
                          <label
                            htmlFor="login-password"
                            className="title-advert"
                          >
                            confirmPassword{" "}
                          </label>
                          <input
                            // id="login-password"
                            type="password"
                            placeholder=""
                            name="confirmPassword"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="half-length">
                      <div className="full-length">
                        <label className="title-advert">Referral Code</label>
                        <input
                          type="text"
                          name="referralCode"
                          value={values.referralCode}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="after-form">
                <div className="tac-pp">
                  <input
                    type="checkbox"
                    onClick={() => setConfirmAgreement(!confirmAgreement)}
                  />
                  <p>
                    I{" "}
                    <span className="lower-text">
                      acknowledge that I have read and accept the{" "}
                      <Link href="https://more.radioadspread.com/knowledgebase/terms-and-conditions/">
                        <span className="advert-color">Terms</span>
                      </Link>{" "}
                      and{" "}
                      <Link href="https://more.radioadspread.com/knowledgebase/terms-and-conditions/">
                        <span className="advert-color">Conditions</span>
                      </Link>{" "}
                      and{" "}
                      <Link href="https://more.radioadspread.com/knowledgebase/privacy-policy/">
                        <span className="advert-color">Privacy Policy</span>
                      </Link>
                    </span>
                  </p>
                </div>
                <div className="login-button">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={confirmAgreement}
                    className={confirmAgreement ? "disable-btn" : "enable-btn"}
                  >
                    {loading ? (
                      <i class="fa fa-circle-o-notch fa-spin"></i>
                    ) : (
                      "Create Account"
                    )}
                  </button>
                </div>
                <div className="already-acct">
                  <div>
                    <p>
                      Already have an account?{" "}
                      <button
                        onClick={() => {
                          setLoginModal((prevState) => !prevState); // Toggle loginModal state
                          closeModal(false); // Close the modal
                        }}
                        className="login-url"
                      >
                        Log in
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdvertiserSignupWrapper>
    </>
  );
};

export default AdvertiserSignup;
