import "./css/login-avp.css";
import { useNavigate } from "react-router-dom";
import { ADVERTISER_LOGIN } from "../components/GraphQL/Mutation";
import { useMutation } from "@apollo/client";
import { AUTH_TOKEN } from "../constant";
import { toast } from "react-toastify";
import { Formik } from "formik";

const LoginAdminPortal = () => {
  const [login, { loading }] = useMutation(ADVERTISER_LOGIN);

  const navigate = useNavigate();

  function handleLogin(values) {
    login({
      variables: {
        input: {
          email: values.email,
          password: values.password,
        },
      },
      onCompleted: handleLoginComplete,
      onError(err) {
        toast.error("invalid details");
      },
    });
  }

  function handleLoginComplete(data) {
    // save jwt token
    localStorage.setItem(AUTH_TOKEN, data.auth.login.token);

    navigate("/admin/dashboard");
  }

  return (
    <div className="admin-form-container">
      <svg
        id="s-logo-lg"
        width="226"
        height="49"
        viewBox="0 0 226 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M137.554 27.051C137.05 26.5548 136.478 26.1305 135.853 25.7903C135.292 25.5133 134.671 25.3751 134.043 25.3878C133.524 25.3921 133.02 25.5599 132.606 25.8662C132.389 25.9972 132.211 26.1808 132.089 26.399C131.966 26.6173 131.903 26.8628 131.907 27.1117C131.907 27.8712 132.419 28.4256 133.46 28.8508C134.501 29.2761 135.566 29.7166 136.661 30.1191C137.772 30.5586 138.758 31.2543 139.535 32.1469C140.406 33.3459 140.82 34.8045 140.708 36.2707C140.769 37.2554 140.609 38.2412 140.24 39.1593C139.871 40.0775 139.302 40.9059 138.572 41.5868C137.073 42.871 135.126 43.5426 133.134 43.4627C131.878 43.4658 130.631 43.2498 129.452 42.8247C128.272 42.4112 127.197 41.7561 126.298 40.9033L129.227 36.8099C129.66 37.3704 130.225 37.821 130.874 38.1236C131.523 38.4263 132.237 38.5722 132.955 38.549C133.579 38.5455 134.19 38.3719 134.719 38.0478C134.978 37.9101 135.193 37.7055 135.341 37.4564C135.489 37.2073 135.564 36.9232 135.558 36.6352C135.558 35.8758 135.045 35.3062 134.004 34.8657L132.412 34.2126L131.635 33.8936L130.804 33.5518C129.7 33.1108 128.722 32.4151 127.953 31.5241C127.077 30.3703 126.655 28.947 126.764 27.5142C126.734 26.2487 127.091 25.0033 127.789 23.9372C128.454 22.9563 129.377 22.1691 130.462 21.6588C131.544 21.1407 132.737 20.8805 133.942 20.8994C135.097 20.9009 136.241 21.1174 137.313 21.5373C138.389 21.9628 139.375 22.5809 140.219 23.36L137.554 27.051Z"
          fill="#004643"
        />
        <path
          d="M141.492 35.018C141.48 33.5298 141.918 32.0712 142.751 30.8258C143.584 29.5542 144.747 28.5216 146.122 27.8336C147.34 27.2005 148.683 26.8308 150.059 26.7495C151.436 26.6683 152.814 26.8774 154.101 27.3627C155.195 27.748 156.199 28.3446 157.052 29.117C157.906 29.8684 158.595 30.7802 159.08 31.7979C159.561 32.8411 159.81 33.9723 159.81 35.1167C159.81 36.261 159.561 37.3923 159.08 38.4355C158.604 39.4519 157.919 40.3617 157.068 41.1088C156.212 41.8786 155.209 42.4749 154.116 42.8631C151.882 43.6532 149.436 43.6532 147.202 42.8631L146.845 42.7112L146.488 42.5593V48.9995H141.508L141.492 35.018ZM146.472 35.1243C146.462 35.8186 146.662 36.5004 147.047 37.0837C147.431 37.6816 147.966 38.1732 148.601 38.5114C149.22 38.8658 149.926 39.052 150.644 39.0506C151.366 39.051 152.076 38.865 152.702 38.5114C153.338 38.1751 153.873 37.6831 154.256 37.0837C154.642 36.5003 154.844 35.819 154.839 35.1243C154.84 34.5979 154.73 34.077 154.514 33.5946C154.299 33.1122 153.984 32.6788 153.588 32.3219C152.808 31.5678 151.756 31.1448 150.659 31.1448C149.562 31.1448 148.51 31.5678 147.73 32.3219C147.331 32.6763 147.012 33.1091 146.796 33.5919C146.579 34.0748 146.469 34.5969 146.472 35.1243Z"
          fill="#004643"
        />
        <path
          d="M168.775 31.4334C168.469 31.2472 168.117 31.1474 167.757 31.1448H167.477C166.211 31.198 165.574 32.0562 165.574 33.7346V42.9847H160.579V33.7346C160.579 29.2943 162.879 26.9603 167.477 26.7324H167.757C168.107 26.7324 168.441 26.778 168.775 26.8236V31.4334Z"
          fill="#004643"
        />
        <path
          d="M186.402 31.7665C186.87 32.8048 187.108 33.928 187.101 35.0625V35.1992C187.105 35.2548 187.105 35.3106 187.101 35.3663V35.6321H185.12H174.4C174.544 36.561 175.02 37.41 175.744 38.0275C176.468 38.6451 177.393 38.9912 178.354 39.004C178.878 39.0086 179.397 38.9079 179.88 38.708C180.362 38.5081 180.797 38.2134 181.159 37.8421L181.205 37.7813L181.252 37.7358V37.6902L183.932 41.4419C183.23 42.0164 182.445 42.4848 181.601 42.8317C180.548 43.2373 179.424 43.4434 178.292 43.4392C176.79 43.4389 175.314 43.0624 174.004 42.3456C172.671 41.6302 171.556 40.582 170.772 39.3078C170.071 38.1746 169.658 36.8933 169.569 35.5717C169.48 34.2501 169.717 32.9268 170.26 31.7133C170.715 30.7114 171.373 29.8098 172.194 29.0628C173.004 28.3031 173.96 27.707 175.006 27.3085C177.138 26.4883 179.508 26.4883 181.64 27.3085C182.706 27.7129 183.678 28.3224 184.499 29.1008C185.313 29.8529 185.961 30.7601 186.402 31.7665ZM178.331 31.1589C177.64 31.1572 176.962 31.3355 176.365 31.6754C175.95 31.931 175.58 32.2517 175.27 32.6247H181.392C181.076 32.2686 180.706 31.9613 180.296 31.7133C179.703 31.3619 179.025 31.1706 178.331 31.1589Z"
          fill="#004643"
        />
        <path
          d="M225.282 35.2005C225.285 36.6908 224.845 38.1499 224.016 39.4002C223.182 40.6716 222.019 41.7041 220.644 42.3925C219.425 43.0208 218.083 43.3878 216.707 43.469C215.331 43.5502 213.954 43.3437 212.666 42.8633C211.575 42.4703 210.573 41.8746 209.714 41.109C208.863 40.3524 208.174 39.4387 207.686 38.4205C207.21 37.3761 206.964 36.2453 206.964 35.1017C206.964 33.9581 207.21 32.8274 207.686 31.7829C208.164 30.7693 208.849 29.8621 209.698 29.1173C210.559 28.3537 211.561 27.7582 212.65 27.3629C213.76 26.9647 214.933 26.7616 216.115 26.763C217.292 26.761 218.46 26.9642 219.564 27.3629L219.929 27.5148L220.287 27.6667V21.2266H225.266L225.282 35.2005ZM220.302 35.0941C220.307 34.4019 220.105 33.7234 219.72 33.1423C219.336 32.5416 218.801 32.0474 218.166 31.707C217.543 31.355 216.835 31.1715 216.115 31.1754C215.393 31.1734 214.683 31.3567 214.057 31.707C213.424 32.0504 212.889 32.544 212.503 33.1423C212.124 33.7257 211.924 34.4031 211.928 35.0941C211.921 35.6223 212.03 36.1459 212.245 36.6302C212.461 37.1146 212.779 37.5488 213.179 37.9041C213.957 38.6577 215.008 39.0805 216.103 39.0805C217.199 39.0805 218.25 38.6577 219.028 37.9041C219.429 37.5484 219.75 37.1147 219.969 36.6308C220.189 36.1469 220.302 35.6235 220.302 35.0941Z"
          fill="#004643"
        />
        <path
          d="M205.472 31.7528C204.984 30.746 204.295 29.8448 203.445 29.1024C202.595 28.3367 201.596 27.7454 200.508 27.3632C199.401 26.956 198.227 26.7477 197.044 26.7481C195.859 26.747 194.684 26.9608 193.579 27.3784C192.493 27.7575 191.495 28.3435 190.643 29.1024C189.796 29.8441 189.111 30.7457 188.631 31.7528C188.07 32.9629 187.825 34.2898 187.917 35.6151C188.01 36.9403 188.436 38.2225 189.159 39.3473C189.993 40.6142 191.149 41.6478 192.515 42.3472C193.906 43.0619 195.456 43.4321 197.028 43.4256C198.213 43.4306 199.388 43.2194 200.493 42.8028C200.648 42.7497 200.897 42.6585 201.223 42.5143V42.9699L206.171 43.0155V35.064C206.188 33.9237 205.95 32.7935 205.472 31.7528ZM201.231 34.7071C201.351 35.724 201.086 36.7488 200.485 37.5874C199.885 38.4261 198.991 39.0205 197.972 39.2581C196.954 39.4957 195.881 39.3601 194.958 38.8768C194.035 38.3936 193.325 37.5964 192.963 36.6359C192.6 35.6755 192.611 34.6185 192.992 33.665C193.373 32.7114 194.098 31.9276 195.031 31.4617C195.963 30.9958 197.038 30.8802 198.052 31.1368C199.065 31.3934 199.948 32.0043 200.532 32.854L202.14 32.6946L201.083 33.9704C201.15 34.2116 201.194 34.4582 201.215 34.7071H201.231Z"
          fill="#004643"
        />
        <path
          d="M0 43.105V21.3239H6.21469C7.79559 21.3003 9.36988 21.5286 10.8757 21.9998C12.119 22.387 13.2062 23.1469 13.983 24.1719C14.6886 25.2502 15.0757 26.4981 15.1017 27.7779C15.1278 29.0577 14.7917 30.3197 14.1306 31.4246C13.5621 32.42 12.6592 33.1926 11.5749 33.6118L17.1215 43.1505H10.7126L5.54661 33.3612H5.48446V43.105H0ZM5.48446 32.055C6.2749 32.081 7.06384 31.9705 7.81497 31.7284C8.38325 31.5454 8.87446 31.1853 9.21327 30.7031C9.56052 30.1574 9.7336 29.5228 9.71045 28.8805C9.75801 28.4447 9.68675 28.0044 9.50393 27.6042C9.3211 27.204 9.03321 26.8583 8.66949 26.6021C7.70484 26.1398 6.63475 25.928 5.56214 25.987L5.48446 32.055Z"
          fill="#004643"
        />
        <path
          d="M29.4023 42.7111C29.0683 42.8554 28.8197 42.9465 28.6255 42.9997C27.5083 43.4225 26.3199 43.6363 25.122 43.6301C23.541 43.6338 21.9832 43.2583 20.5852 42.5364C19.1974 41.8342 18.0237 40.7863 17.1827 39.4986C16.4653 38.3595 16.046 37.0651 15.962 35.7299C15.878 34.3947 16.1318 33.0599 16.7011 31.8434C17.1858 30.8256 17.8754 29.9139 18.7286 29.1625C19.5901 28.4001 20.5954 27.8094 21.6883 27.4234C22.8034 27.0027 23.9889 26.789 25.1841 26.793C26.3801 26.7919 27.5667 27.0002 28.6876 27.4082C29.7816 27.7991 30.787 28.395 31.6474 29.1625C32.5048 29.9137 33.1995 30.8253 33.6905 31.8434C34.1886 32.8921 34.4407 34.0364 34.4284 35.1926V43.2199L29.4412 43.1668L29.4023 42.7111ZM21.5407 33.2332C21.1566 33.8258 20.9544 34.5137 20.9581 35.2153C20.9554 35.7448 21.0659 36.269 21.2826 36.7542C21.4994 37.2394 21.8175 37.675 22.2166 38.0329C23.0092 38.7832 24.0692 39.2026 25.1724 39.2026C26.2757 39.2026 27.3357 38.7832 28.1283 38.0329C28.5274 37.675 28.8455 37.2394 29.0623 36.7542C29.279 36.269 29.3895 35.7448 29.3868 35.2153C29.3905 34.5137 29.1883 33.8258 28.8042 33.2332C28.419 32.6299 27.8847 32.131 27.2505 31.7826C26.6155 31.4436 25.9037 31.266 25.1802 31.266C24.4568 31.266 23.7449 31.4436 23.1099 31.7826C22.4701 32.129 21.9303 32.628 21.5407 33.2332Z"
          fill="#004643"
        />
        <path
          d="M53.6714 35.3218C53.6731 36.8235 53.2277 38.2933 52.3897 39.5519C51.553 40.8431 50.3782 41.892 48.9871 42.5897C47.7597 43.2263 46.407 43.5984 45.02 43.6809C43.6331 43.7634 42.2442 43.5545 40.9469 43.0681C39.8452 42.6765 38.8339 42.075 37.9716 41.2986C37.1044 40.5276 36.4067 39.5923 35.9208 38.5494C35.4393 37.4969 35.1904 36.357 35.1904 35.204C35.1904 34.0511 35.4393 32.9111 35.9208 31.8587C36.4004 30.8316 37.0936 29.9134 37.9561 29.1626C38.8198 28.3881 39.8307 27.7869 40.9313 27.3931C43.1893 26.5892 45.6649 26.5892 47.9229 27.3931L48.2802 27.545L48.6453 27.6969V21.2188H53.6714V35.3218ZM48.6453 35.2154C48.652 34.5158 48.4495 33.8295 48.0627 33.2409C47.6775 32.6375 47.1432 32.1387 46.509 31.7903C45.8831 31.438 45.1727 31.2546 44.4504 31.2587C43.7209 31.2556 43.0032 31.4388 42.3685 31.7903C41.7239 32.1325 41.1786 32.6289 40.7837 33.2333C40.397 33.8219 40.1945 34.5082 40.2011 35.2078C40.1983 35.7391 40.3095 36.2651 40.5276 36.7518C40.7457 37.2384 41.0659 37.6749 41.4674 38.033C42.2509 38.7973 43.3125 39.2265 44.4193 39.2265C45.5262 39.2265 46.5878 38.7973 47.3713 38.033C47.7732 37.6764 48.0943 37.2415 48.3137 36.7562C48.5331 36.2709 48.6461 35.746 48.6453 35.2154Z"
          fill="#004643"
        />
        <path
          d="M95.2016 39.3075H86.2757L84.0851 43.1807H78.4297L90.7425 21.0352L103.048 43.1807H97.3922L95.2016 39.3075ZM92.9332 35.2749L90.7425 31.3941L88.5441 35.2749H92.9332Z"
          fill="#004643"
        />
        <path
          d="M120.239 35.3218C120.247 36.8233 119.804 38.2942 118.965 39.5519C118.128 40.8431 116.953 41.892 115.562 42.5897C114.333 43.2265 112.979 43.5986 111.591 43.6811C110.203 43.7637 108.813 43.5546 107.514 43.0681C106.414 42.6731 105.403 42.072 104.539 41.2986C103.676 40.5252 102.981 39.5903 102.496 38.5494C102.009 37.4982 101.758 36.3577 101.758 35.204C101.758 34.0503 102.009 32.9099 102.496 31.8587C102.975 30.8335 103.665 29.9158 104.523 29.1626C105.388 28.3897 106.399 27.7887 107.498 27.3931C109.756 26.5892 112.232 26.5892 114.49 27.3931L114.855 27.545L115.22 27.6969V21.2188H120.239V35.3218ZM115.22 35.2154C115.225 34.515 115.019 33.8287 114.63 33.2409C114.245 32.6371 113.711 32.1382 113.076 31.7903C112.447 31.4381 111.734 31.2547 111.01 31.2587C110.283 31.2555 109.568 31.4388 108.936 31.7903C108.301 32.1387 107.767 32.6375 107.382 33.2409C106.995 33.8295 106.793 34.5158 106.799 35.2154C106.795 35.7462 106.905 36.2718 107.122 36.7584C107.339 37.245 107.658 37.6818 108.058 38.0406C108.841 38.8049 109.903 39.2341 111.01 39.2341C112.117 39.2341 113.178 38.8049 113.962 38.0406C114.362 37.6818 114.68 37.245 114.897 36.7584C115.114 36.2718 115.224 35.7462 115.22 35.2154Z"
          fill="#004643"
        />
        <path
          d="M78.2045 31.8433C77.721 30.814 77.0286 29.8916 76.1692 29.1321C75.3066 28.3597 74.2951 27.7632 73.1939 27.3777C70.9396 26.5538 68.4567 26.5538 66.2024 27.3777C65.1044 27.777 64.0945 28.3776 63.2271 29.1473C62.3693 29.9023 61.677 30.8195 61.1918 31.8433C60.6311 33.0722 60.3895 34.4174 60.4884 35.7594C60.5873 37.1015 61.0237 38.399 61.7588 39.5365C62.599 40.8249 63.7729 41.873 65.1614 42.5743C66.3902 43.2111 67.7442 43.5833 69.1324 43.6658C70.5206 43.7483 71.9107 43.5393 73.2094 43.0528C74.3074 42.6536 75.3172 42.053 76.1847 41.2833C77.0467 40.5171 77.7393 39.5867 78.22 38.5493C78.7013 37.4941 78.9501 36.3516 78.9501 35.1963C78.9501 34.0409 78.7013 32.8985 78.22 31.8433H78.2045ZM73.9396 34.7064C74.0654 35.7287 73.8034 36.7606 73.2032 37.6066C72.603 38.4527 71.7063 39.0543 70.6828 39.2973C69.6594 39.5404 68.5803 39.4082 67.6499 38.9257C66.7196 38.4432 66.0024 37.6439 65.6344 36.6792C65.2664 35.7144 65.273 34.6513 65.653 33.691C66.0329 32.7307 66.7599 31.94 67.6962 31.4685C68.6326 30.9971 69.7132 30.8777 70.7335 31.1329C71.7539 31.3881 72.6431 32.0002 73.2327 32.8534L74.833 32.6939L73.7765 33.9698C73.8536 34.21 73.9082 34.4566 73.9396 34.7064Z"
          fill="#004643"
        />
        <path
          d="M58.2228 9.83594L59.6677 43.2062H54.6416L56.0865 9.85113L58.2228 9.83594Z"
          fill="#FC9732"
        />
        <path
          d="M53.9265 41.1465H60.2033C60.7617 41.1465 61.2972 41.3633 61.692 41.7493C62.0868 42.1353 62.3086 42.6588 62.3086 43.2046H51.8213C51.8213 42.6588 52.0431 42.1353 52.4379 41.7493C52.8327 41.3633 53.3682 41.1465 53.9265 41.1465Z"
          fill="#FC9732"
        />
        <path
          d="M57.0963 5.15594e-05C55.7428 -0.004029 54.4019 0.254295 53.1511 0.760082C51.9004 1.26587 50.7646 2.00908 49.8096 2.94672L51.037 4.15425C51.8312 3.37239 52.7758 2.75187 53.8164 2.32843C54.857 1.90499 55.973 1.68699 57.1002 1.68699C58.2273 1.68699 59.3433 1.90499 60.3839 2.32843C61.4245 2.75187 62.3691 3.37239 63.1634 4.15425L64.3908 2.94672C63.4343 2.00885 62.2972 1.26556 61.0451 0.759791C59.7931 0.254023 58.451 -0.00419588 57.0963 5.15594e-05Z"
          fill="#FC9732"
        />
        <path
          d="M52.2334 5.35473L53.4686 6.56226C53.9412 6.09956 54.5024 5.73248 55.1203 5.48203C55.7381 5.23158 56.4004 5.10267 57.0692 5.10267C57.738 5.10267 58.4003 5.23158 59.0182 5.48203C59.636 5.73248 60.1972 6.09956 60.6698 6.56226L61.8972 5.35473C60.6144 4.10443 58.8768 3.40234 57.0653 3.40234C55.2539 3.40234 53.5163 4.10443 52.2334 5.35473Z"
          fill="#FC9732"
        />
        <path
          d="M57.0652 9.14366C58.0348 9.14366 58.8209 8.37522 58.8209 7.4273C58.8209 6.47938 58.0348 5.71094 57.0652 5.71094C56.0956 5.71094 55.3096 6.47938 55.3096 7.4273C55.3096 8.37522 56.0956 9.14366 57.0652 9.14366Z"
          fill="#FC9732"
        />
      </svg>

      <h2>Admin Login</h2>

      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={handleLogin}
      >
        {({ values, handleSubmit, handleChange, isSubmitting }) => (
          <form className="admin-login-form" onSubmit={handleSubmit}>
            <div className="admin-input-container">
              <label htmlFor="Username" className="admin-label">
                Username
              </label>
              <input
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="John Doe"
                required
                className="admin-login-input"
              />
            </div>

            <div className="admin-input-container">
              <label htmlFor="password" className="admin-label">
                Password
              </label>
              <input
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder="Enter your Password"
                required
                className="admin-login-input"
              />
            </div>

            <button className="admin-login-button" type="submit">
              {loading ? (
                <i class="fa fa-circle-o-notch fa-spin"></i>
              ) : (
                "Log in"
              )}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginAdminPortal;
