import * as yup from "yup";
export const updateProfileSchema = yup.object({
  fullname: yup.string().required("Full name is required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .matches(/^\+234\d{10}$/, "Phone number must be valid")
    .required("Phone Number is required"),
  country: yup.string().required("country is required"),
  address: yup.string().required("address is required"),
  zipcode: yup.string().required("Postal Code  is required"),
  radioStationWebsite: yup
    .string()
    .url("Must be a valid URL")
    .required("Radio Station Website is required"),
  radioStationDigitalStreaUrl: yup
    .string()
    .url("Must be a valid URL")
    .required("Radio Station Digital Stream URL is required"),

  radioStationCategory: yup
    .string()
    .required("Radio Station Category is required"),
});

export const updateAccountSchema = yup.object({
  accountName: yup.string().required("Account name is required"),
  accountNumber: yup
    .string()
    .matches(/^[0-9]{10}$/, "Account number must be valid")
    .required("Account Number is required"),
});

export const AdvertiserSignupSchema = yup.object().shape({
  companyName: yup.string().when("accountType", {
    is: (accountType) => accountType === "company",
    then: yup
      .string()
      .min(3, "Company name must be more than three characters long")
      .required("Company Name is required"),
    otherwise: yup.string(),
  }),
  firstName: yup
    .string()
    .min(2, "Full name must be more than two characters long")
    .required("First name is required"),
  lastName: yup
    .string()
    .min(2, "Full name must be more than two characters long")
    .required("Last name is required"),
  // address: yup
  //   .string()
  //   .min(3, "Address must be more than three characters long")
  //   .required("Address is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .matches(/^\+234\d{10}$/, "Phone number is not valid")
    .required("Phone number is required"),
  password: yup
    .string()
    .min(8, "Minimum of 8 characters password")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords does not match password")
    .required("confirm password is required"),
});

export const BroadcasterSignupSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Name must be more than three characters long")
    .required("Name is required"),
  fullname: yup
    .string()
    .min(3, "Full Name must be more than three characters long")
    .required("Full Name is required"),
  address: yup
    .string()
    .min(3, "Address must be more than three characters long")
    .required("Address is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .matches(/^\+234\d{10}$/, "Phone number is not valid")
    .required("Phone number is required"),
  radioStationWebsite: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required("Enter radio statio website"),
  password: yup
    .string()
    .min(8, "Minimum of 8 characters password")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords does not match password")
    .required("confirm password is required"),
});

export const PortfolioSchema = yup.object().shape({
  title: yup
    .string()
    .min(3, "Title must be 3 characters at minimum")
    .required("Title is required"),
  // propertyId: yup.string().required("Property ID is required"),
  metric: yup.string().required("metric is required"),
});

export const AdminAdvertiserProfileSchema = yup.object().shape({
  companyName: yup.string().when("accountType", {
    is: (accountType) => accountType === "company",
    then: yup
      .string()
      .min(3, "Company name must be more than three characters long")
      .required("Company Name is required"),
    otherwise: yup.string(),
  }),
  industry: yup.string().when("accountType", {
    is: (accountType) => accountType === "company",
    then: yup
      .string()
      .min(3, "Industry name must be more than three characters long")
      .required("Industry Name is required"),
    otherwise: yup.string(),
  }),
  address: yup
    .string()
    .min(3, "Address must be more than three characters long")
    .required("Address is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  zipCode: yup.string(),
  phoneNumber: yup.string().required("Phone number is required"),
});

export const UpdateCampaignScheme = yup.object().shape({
  campaignName: yup
    .string()
    .min(3, "Campaign Name must be more than three characters long")
    .required("Campaign Name is required"),
  companyName: yup
    .string()
    .min(3, "Company name must be more than three characters long")
    .required("Company name is required"),
  industry: yup
    .string()
    .min(3, "Industry must be more than three characters long")
    .required("Industry is required"),
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const updateRadioStationProfileDetailsValidationSchema = yup
  .object()
  .shape({
    radioStation: yup
      .string()
      .required("Radio Station Name is required")
      .min(3, "Radio Station Name must be three characters or more characters"),
    nameOfPersonInCharge: yup
      .string()
      .required("Name of Person in Charge is required")
      .min(3, "name must be three characters or more characters"),
    position: yup
      .string()
      .required("Position is required")
      .min(3, "must be three or more character"),
    contactNo: yup
      .string()
      .matches(phoneRegExp, "Contact Number is not valid")
      .required("Contact Number is required"),
    address: yup
      .string()
      .required("Address is required")
      .min(5, "must be five or more characters"),
    country: yup
      .string()
      .required("Country is required")
      .min(3, "name must be three characters or more characters"),
    state: yup
      .string()
      .required("State is required")
      .min(3, "name must be three characters or more characters"),
    city: yup
      .string()
      .required("City is required")
      .min(3, "name must be three characters or more characters"),
    zipcode: yup
      .string()
      .required("Zip Code is required")
      .min(3, "name must be three characters or more characters"),
    radioCategory: yup
      .string()
      .required("Radio Category is required")
      .min(3, "name must be three characters or more characters"),
    website: yup.string().url("Invalid URL").required("Website is required"),
    digitalStreamUrl: yup
      .string()
      .url("Invalid URL")
      .required("Digital Stream URL is required"),
  });
