import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TableSpinner } from "../components/Spinner";
import Logo from "../components/Logo";
import { AdminMenuList } from "../utils/constant";
import { useMutation, useQuery } from "@apollo/client";
import { GET_UNAPPROVED_BROADCASTERS } from "../components/GraphQL/Queries";
import { VERIFY_BROADCASTER_EMAIL } from "../components/GraphQL/Mutation";
import { APPROVE_BROADCASTER_EMAIL } from "../components/GraphQL/Mutation";
import { toast } from "react-toastify";
import { EmptyState } from "../components/Modal/ScheduleModal";
import { ChakraProvider, useDisclosure } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import {
  LogOutIcon,
  PopoverText,
  ProfileIcon,
} from "./ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/components";
import Notification from "./ResponsiveAdvertiserDashboard/assets/notification.svg";
import UserIcon from "./ResponsiveAdvertiserDashboard/assets/user.svg";
import PersonIcon from "./ResponsiveAdvertiserDashboard/assets/person.svg";
import "./ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/vendorDashboard/style.css";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  HStack,
  VStack,
  Skeleton,
  Container,
} from "@chakra-ui/react";
import Logout from "../components/Modal/Logout/Logout";
import NotificationsPopOver from "../components/Notifications/NotificationsPopOver";
const AdminDashboardLayout = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isNotificationModal,
    onOpen: openNotificationModal,
    onClose: closeNotificationModal,
  } = useDisclosure();

  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    //Check for the store state and update the state.
    const storedIndex = localStorage.getItem("selectedItemIndex");
    setSelectedItemIndex(storedIndex !== null ? Number(storedIndex) : 0);
    if (selectedItemIndex && AdminMenuList.length >= 0) {
      navigate(AdminMenuList[selectedItemIndex].link);
    }
  }, []);

  const breakpoints = {
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  };

  const theme = extendTheme({ breakpoints });

  const handleMenuItemClick = (index, itemId) => {
    setSelectedItemIndex(index);
    localStorage.setItem("selectedItemIndex", index);
  };

  return (
    <ChakraProvider theme={theme}>
      {isOpen && <Logout isOpen={isOpen} onClose={onClose} />}
      <div className="desktop-dashboard-layout">
        <div className="dashboard-sidebar-container">
          <div>
            <Logo textColor="#FFFFFE" favColor="#FC9732" />
          </div>

          <div className="dashboard-sidebar-menu-items">
            {AdminMenuList.map(({ text, Icon, link }, index) => {
              return (
                <Link
                  to={link}
                  onClick={() => handleMenuItemClick(index, index.id)}
                >
                  <div
                    className={`sidebar-menu-item ${
                      selectedItemIndex === index ? "selected" : ""
                    }`}
                  >
                    <Icon />
                    <span>{text}</span>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="dashboard-nav-body-container">
          <nav>
            <div>Super Administrator</div>

            <div className="nav-icon-wrapper">
              <div
                onClick={openNotificationModal}
                style={{ cursor: "pointer", position: "relative" }}
              >
                <img src={Notification} alt="notification" />

                {isNotificationModal && (
                  <NotificationsPopOver
                    isNotificationModal={isNotificationModal}
                    openNotificationModal={openNotificationModal}
                    closeNotificationModal={closeNotificationModal}
                  />
                )}
              </div>

              <Popover placement="bottom-start">
                <PopoverTrigger>
                  <div style={{ cursor: "pointer" }}>
                    <img src={UserIcon} alt="user-icon" />
                  </div>
                </PopoverTrigger>

                <PopoverContent
                  w="158px"
                  h="89px"
                  bg="#ffffff"
                  border="0.5px solid #aba8a8"
                  boxShadow="none"
                  // borderRadius="2px"
                  p="15px 0px"
                >
                  <VStack>
                    <Container

                    // onClick={() => navigate("/dashboard/profile")}
                    >
                      <HStack>
                        <ProfileIcon />
                        <PopoverText>Profile</PopoverText>
                      </HStack>
                    </Container>
                    <Container onClick={onOpen}>
                      <HStack>
                        <LogOutIcon />
                        <PopoverText>Logout</PopoverText>
                      </HStack>
                    </Container>
                  </VStack>
                </PopoverContent>
              </Popover>
            </div>
          </nav>

          {children}
        </div>
      </div>
    </ChakraProvider>
  );
};

export default AdminDashboardLayout;
