import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import WaveIcon from "../../transmitAssets/Wave";
import MobileWaveIcon from "../../transmitAssets/MobileWave";
import "./style.css";

const TransmitObjective = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 0,
    });
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="transmitObjective" data-aos="zoom-out-right">
      <div className="transmitObjectiveWrapper">
        <div className="transmitObjectiveHeader">
          <h1 className="transmitObjectiveTitle" data-aos="fade-up-right" style={{ textTransform: "lowercase" }}>

            transmit <span >for...</span>
          </h1>
        </div>

        <div className="transmitOjectiveSection">
          <div className="transmitAdvertiserObjective">
            <h1 className="transmitAdvertiserTitle" data-aos="fade-up-right">
              Advertisers
            </h1>
            <ul className="transmitAdvertiserLists">
              <li className="transmitAdvertiserList" data-aos="fade-up-right">
                A<span className="lower-text">dvertisers can easily run radio advertising on any radio
                station of their choice</span>
              </li>
              <li className="transmitAdvertiserList" data-aos="fade-up-right">
                C<span className="lower-text">ontact between radio stations and advertisers is made simple</span>
              </li>
              <li className="transmitAdvertiserList" data-aos="fade-up-right">
                R<span className="lower-text">eal-time Campaign Performance data</span>
              </li>
            </ul>
          </div>

          <div className="divider"></div>

          <div className="transmitRadioStationObjective">
            <h1 className="transmitRadioStationTitle" data-aos="fade-up-left">
              Radio Stations
            </h1>
            <ul className="transmitRadioStationLists">
              <li className="transmitRadioStationList" data-aos="fade-up-left">
                A<span className="lower-text">ccessibility to a wider range of advertisers</span>
              </li>
              <li className="transmitRadioStationList" data-aos="fade-up-left">
                P<span className="lower-text">rompt payment for all reserved slots</span>
              </li>
              <li className="transmitRadioStationList" data-aos="fade-up-left">
                E<span className="lower-text">ase of inventory and booking management</span>
              </li>
              <li className="transmitRadioStationList" data-aos="fade-up-left">
                R<span className="lower-text">evenue tracking</span>
              </li>
              <li className="transmitRadioStationList" data-aos="fade-up-left">
                T<span className="lower-text">racking radio station performance</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="waveIconWrapper" data-aos="fade-up-right">
        {width < 768 ? <MobileWaveIcon /> : <WaveIcon />}
      </div>
    </div>
  );
};

export default TransmitObjective;
