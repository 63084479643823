import React, { useEffect } from "react";
import { SocialLink } from "../svgs";
import "./footer.css";
import Logo from "../../transmitAssets/transmitLogo.png";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { MdFacebook, MdOutlinePhone, MdLocationOn } from "react-icons/md";
import { FaLinkedin, FaSquareXTwitter, FaInstagram } from "react-icons/fa6";

const Footer = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 0,
    });
  }, []);
  return (
    <section className="footer-sect" data-aos="zoom-out-right">
      <div className="footer-wrapper">
        <div className="footer-first-sec">
          <div className="about-transmit">
            <Link to="/" data-aos="fade-right">
              <img src={Logo} alt="transmitLogo" className="transmitLogo" />
            </Link>
            <p className="transmit" data-aos="fade-up-right">
              T<span className="lower-case">ransmit is an AI-driven global advertisement platform that
              digitalizes the operation of radio advert globally while providing
              real-time report of the campaign.</span>
            </p>
          </div>

          <div className="links-wrapper">
            <div className="links" data-aos="fade-down-right">
              <span>
                <a href="https://more.radioadspread.com/knowledgebase/contact-us/">
                  Contact Us
                </a>
              </span>
              <span>
                <a href="https://more.radioadspread.com/knowledgebase/contact-us/">
                  FAQ
                </a>
              </span>
              <span>
                <a href="https://more.radioadspread.com/knowledgebase/contact-us/">
                  Blog
                </a>
              </span>
              <span>
                <a href="https://more.radioadspread.com/knowledgebase/contact-us/">
                  Radio Stations
                </a>
              </span>
            </div>

            <div className="links" data-aos="fade-left">
              <span>
                <a href="https://more.radioadspread.com/knowledgebase/about-us/">
                  About us
                </a>
              </span>
              <span>
                <a href="https://more.radioadspread.com/knowledgebase/contact-us/">
                  Terms and Conditions
                </a>
              </span>
              <span>
                <a href="https://more.radioadspread.com/knowledgebase/cookie-policy/">
                  Cookie and Policy
                </a>
              </span>
              <span>
                <a href="https://more.radioadspread.com/knowledgebase/privacy-policy/">
                  Privacy policy
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="icons-wrapper" data-aos="fade-up-right">
          <div className="connect">
            <p>Connect with us</p>
          </div>
          <div className="icons-sect" >
            <SocialLink
              href="https://web.facebook.com/people/Radioadspread/100077786237234/"
              target="_blank"
            >
              <MdFacebook className="social-icon" />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/company/radioadspread/"
              target="_blank"
            >
              <FaLinkedin className="social-icon" />
            </SocialLink>
            <SocialLink
              href="https://twitter.com/radioadspread"
              target="_blank"
            >
              <FaSquareXTwitter className="social-icon" />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/radioadspread.africa/"
              target="_blank"
            >
              <FaInstagram className="social-icon" />
            </SocialLink>
          </div>
        </div>
      </div>
      <div className="address-wrapper" data-aos="fade-right">
        <address className="addres">
          <div className="address-icon" data-aos="fade-left">
            <span>
              <MdOutlinePhone className="social-icon" />
            </span>
            <span className="address-text">(+234) 81 1577 5813</span>
          </div>
          <div className="address-icon" data-aos="fade-left">
            <span>
              <MdLocationOn className="social-icon" />
            </span>
            <span className="address-text">
              2b Montgomery Rd, Yaba, Lagos, Nigeria
            </span>
          </div>
        </address>
      </div>
    </section>
  );
};

export default Footer;
